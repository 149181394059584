import { QsHttpService } from '../core/http/qs-http.service';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IDataPayloadSingle } from '../core/http/http.interface';
import { IHomeContent } from './home.interface';

@Injectable({ providedIn: 'root' })
export class HomeService {
  protected readonly _http: QsHttpService = inject(QsHttpService);

  public getHomePageContent(): Observable<IDataPayloadSingle<IHomeContent>> {
    const params = [
      'populate=seo.metaImage,heroImage,quickConnectCta,quickConnectImage,printImage,printCta,print_products.icon,print_products.productListCtas,heroClients,newsletterCta',
    ];

    return this._http.get<IDataPayloadSingle<IHomeContent>>(`/home-page?${params.join('&')}`);
  }
}
