import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { IDataPayloadMultiple, IDataPayloadSingle } from './core/http/http.interface';
import { IHomeContent } from './home/home.interface';
import { HomeService } from './home/home.service';
import { ISearchResponse } from './components/search-bar/search-bar.interface';
import { SearchBarService } from './components/search-bar/search-bar.service';
import { IContentMenuItem } from './components/main-navigation/main-navigation.interface';
import { IContent } from './content/content.interface';
import { SubMenuContentService } from './components/main-navigation/sub-menu/sub-menu-content/sub-menu-content.service';

export const homeResolver: ResolveFn<IDataPayloadSingle<IHomeContent>> = () => {
  return inject(HomeService).getHomePageContent();
}

export const industriesResolver: ResolveFn<IDataPayloadMultiple<IContentMenuItem>> = () => {
  return inject(SubMenuContentService).getIndustries();
}

export const militariesResolver: ResolveFn<IDataPayloadMultiple<IContentMenuItem>> = () => {
  return inject(SubMenuContentService).getMilitaries();
}

export const guidesResolver: ResolveFn<IDataPayloadMultiple<IContent>> = () => {
  return inject(SubMenuContentService).getNewGuides();
}

export const searchResolver: ResolveFn<Promise<ISearchResponse | null>> = (route: ActivatedRouteSnapshot) => {
  if (!route.queryParams['searchTerm']) {
    return Promise.resolve(null);
  }

  return inject(SearchBarService).globalSearch(route.queryParams['searchTerm'].split('-').join(' '));
}
